var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"height":"90%","line-height":"normal"}},_vm._l((_vm.groupedZusatzleistungen),function(verlaengerung){return _c('div',{key:verlaengerung.beschreibung +
      ' ' +
      verlaengerung.verfuegbarkeitsStatus +
      ' ' +
      verlaengerung.reiseterminId,attrs:{"id":verlaengerung.beschreibung +
      ' ' +
      verlaengerung.verfuegbarkeitsStatus +
      ' ' +
      verlaengerung.reiseterminId}},[_c('div',{staticClass:"einheiten-frei rounded",class:_vm.istBgColor(verlaengerung) + ' mr-1'},[_vm._v(" "+_vm._s(verlaengerung.verfuegbarkeitsStatus == 'Verfuegbar' ? 'J' : verlaengerung.verfuegbarkeitsStatus == 'NichtVerfuegbar' ? 'N' : 'RQ')+" ")]),_c('div',{staticClass:"text-center zimmertyp"},[_vm._v(" "+_vm._s(verlaengerung.beschreibung .split(' ') .map(word => word.substring(0, 1)) .join(''))+" ")]),_c('b-tooltip',{attrs:{"target":verlaengerung.beschreibung +
        ' ' +
        verlaengerung.verfuegbarkeitsStatus +
        ' ' +
        verlaengerung.reiseterminId,"custom-class":"custom-tooltip-verlaengerung-widget"}},[_c('b',[_vm._v(" "+_vm._s(verlaengerung.beschreibung))]),_vm._l((verlaengerung.hotels),function(value,key){return _c('div',{key:verlaengerung.beschreibung +
          ' ' +
          verlaengerung.verfuegbarkeitsStatus +
          ' ' +
          verlaengerung.reiseterminId +
          ' ' +
          key,staticClass:"d-flex mt-2 justify-content-between"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(key)+" ("+_vm._s(_vm.getKuerzel(key, verlaengerung.beschreibung))+"):")]),_c('div',{staticClass:"d-flex"},_vm._l((value),function(value,key){return _c('div',{key:verlaengerung.beschreibung +
              ' ' +
              verlaengerung.verfuegbarkeitsStatus +
              ' ' +
              verlaengerung.reiseterminId +
              ' ' +
              key +
              ' ' +
              value},[_c('div',{staticClass:"einheiten-frei rounded",class:_vm.istBgColor(verlaengerung) + ' mr-1'},[_vm._v(" "+_vm._s(verlaengerung.verfuegbarkeitsStatus == 'Verfuegbar' ? value : verlaengerung.verfuegbarkeitsStatus == 'NichtVerfuegbar' ? 'N' : 'RQ')+" ")]),_c('div',{staticClass:"text-center zimmertyp"},[_vm._v(" "+_vm._s(key)+" ")])])}),0)])})],2)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
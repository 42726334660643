<template>
  <FilterOverview :showFilterInitially="false" title="Reisefinder" @reload="onReload">
    <template #title-right>
      <b-button
        class="btn-xs btn p-2 ml-2 justify-content-center align-item-center d-flex"
        v-b-tooltip.hover
        :title="reisefinderTitleHoverText"
      >
        <i class="fas fa-info m-0 p-0"></i>
      </b-button>
    </template>
    <template #toolbar-left>
      <div
        class="rounded mr-5 d-flex justify-content-center align-items-center filterReisefinder"
        :class="{ filterActive }"
        @click="onClickFilterButton"
        @keyup="handleKeyDown"
      >
        <i class="fas fa-filter filterIcon"></i>
      </div>
      <b-form-checkbox
        class="mr-5"
        v-model="ausgebuchteTermineCheckbox"
        @change="ausgebuchteTermineChanged"
        switch
      >
        Ausgebuchte Termine anzeigen
      </b-form-checkbox>
      <b-button size="sm" class="ml-4" variant="danger" v-if="checkIfFilterSupplied" @click="resetAllFilters">
        Alle Filter zurücksetzen
      </b-button>
      <!-- Reisefinder Filter -->
      <b-modal
        id="modal-reisefinder"
        :visible="filterActive"
        @hide="filterActive = false"
        size="lg"
        title="Filter Reisefinder"
      >
        <div class="d-flex flex-column justify-content-center align-items-center">
          <b-row>
            <CustomMultiselect
              style="width: 220px"
              :show-labels="false"
              autofocus
              id="filter-reisekuerzel"
              label="reisekuerzel"
              class="mt-4 mr-4"
              track-by="id"
              :loading="reiseterminSearchLoading"
              @input="changeReisekuerzel($event)"
              @search-change="onSearchChangeReisetermin"
              :options="reisekuerzelOptions"
              placeholder="Reisekürzelsuche"
              :value="inputReiseterminkuerzel"
              :multiple="true"
            ></CustomMultiselect>
            <CustomMultiselect
              class="mt-4"
              placeholder="Reisetitel"
              id="filter-reisetitel"
              :options="reisetitelOptions"
              :loading="reisetitelSearchLoading"
              @input="changeReisetitel($event)"
              @search-change="onSearchChangeReisetitel"
              :show-labels="false"
              label="titel"
              track-by="id"
              :value="inputReisetitel"
              :multiple="true"
              style="width: 220px"
            />
          </b-row>

          <b-row class="mt-4">
            <DatePicker
              :lang="langConfig"
              v-model="dateAbflug"
              placeholder="Frühester Abflug"
              format="DD.MM.YY"
              class="mr-4"
              style="width: 220px; height: 33.8438px"
            ></DatePicker>
            <DatePicker
              :lang="langConfig"
              v-model="dateAnkunft"
              placeholder="Späteste Ankunft"
              format="DD.MM.YY"
              style="width: 220px; height: 33.8438px"
            ></DatePicker>
          </b-row>
          <b-row class="mt-6">
            <FilterRegionCountry
              :countriesGroupedByRegions="countriesGroupedByRegions"
              :valueRegion="regionSelected"
              :valueCountry="countrySelected"
              @inputRegion="setRegionFilter"
              @inputCountry="setCountryFilter"
              style="width: 350px"
              horizontal
            />
            <CustomMultiselect
              class="ml-4"
              placeholder="Rund-/Aufenthaltsreise"
              id="filter-rundaufenthaltsreise"
              v-model="rundAufenthaltsreiseSelected"
              :options="rundAufenthaltsreiseOptions"
              :show-labels="false"
              style="width: 220px"
            />
          </b-row>

          <div class="d-flex justify-content-center flex-lg-wrap text-muted mt-4" style="width: 350px">
            <b-input-group size="sm" title="Interessenten" prepend="Interessenten">
              <b-form-input
                title="PAX"
                aria-label="PAX"
                class="mr-xl-2 mr-lg-0"
                size="sm"
                type="number"
                v-model.number="pax"
              ></b-form-input>
              <b-input-group-prepend>
                <b-button title="1 Interessent" variant="outline-secondary" @click="pax = 1">1</b-button>
                <b-button title="2 Interessenten" variant="outline-secondary" @click="pax = 2">2</b-button>
              </b-input-group-prepend>
            </b-input-group>

            <CustomMultiselect
              class="mt-6"
              placeholder="Garantierte Reisetermine"
              v-model="garantiertFilterSelected"
              :options="garantiertFilterOptions"
              id="filter-garantiert"
              :show-labels="false"
              style="width: 350px"
            />
            <b-input-group class="mt-2">
              <b-form-checkbox v-model="zimmerkontingentBeruecksichtigen">
                Nur durchbuchbare Termine anzeigen
              </b-form-checkbox>
            </b-input-group>
          </div>
        </div>
        <template #modal-footer>
          <b-button variant="primary" @click="filterAnwenden">Anwenden</b-button>
        </template>
      </b-modal>
    </template>
    <template #table="{ tableHeight }">
      <ag-grid-vue
        :style="{ height: tableHeight + 80 + 'px' }"
        class="ag-theme-alpine m-0 p-0"
        :columnDefs="columnDefs"
        :getRowHeight="getRowHeight"
        :rowModelType="rowModelType"
        :statusBar="statusBar"
        :tooltipShowDelay="0"
        :autoSizeStrategy="autoSizeStrategy"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
      ></ag-grid-vue>
    </template>
  </FilterOverview>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import FilterRegionCountry from '@/components/produkte/reisetermine/filter-region-country.vue';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import FilterOverview from '@/components/common/filter-overview.vue';
import CellRendererZimmerkontingente from '@/components/common/zimmerkontingente/cell-renderer-zimmerkontingente.vue';
import { mapGetters, mapState } from 'vuex';
import { GET_REISETERMINE } from '@/core/produkte/reisetermine/stores/reisefinder.module';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import { differenceInDays, parseISO, format } from 'date-fns';
import CellRendererPax from '@/components/produkte/reisetermine/cell-renderer-pax.vue';
import CellRendererActionButtons from '@/components/produkte/reisetermine/cell-renderer-action-buttons.vue';
import CellRendererFlugverfuegbarkeiten from '@/components/produkte/reisetermine/cell-renderer-flugverfuegbarkeiten.vue';
import CellRendererVerlaengerung from '@/components/common/cell-renderer-verlaengerung.vue';
import CellRendererOptionals from '@/components/common/cell-renderer-optionals.vue';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';

import { GET_ALL_COUNTRIES_GROUPED_BY_REGIONS } from '@/core/produkte/reisen/stores/reisen.module';
import CustomMultiselect from '@/components/common/custom-multiselect.vue';
import DateRangePicker from '@/components/common/date-range-picker.vue';
import buildQuery from 'odata-query';
import apiService from '@/core/common/services/api.service';
import * as applicationInsights from '@/core/common/services/application-insights.service';
import { langConfig } from '@/core/common/helpers/utils.js';

export default {
  name: 'App',
  components: {
    DatePicker,
    AgGridVue,
    FilterRegionCountry,
    FilterOverview,
    DateRangePicker,
    CellRendererZimmerkontingente,
    StatusBarComponent,
    CellRendererPax,
    CellRendererActionButtons,
    CustomMultiselect,
    CellRendererFlugverfuegbarkeiten,
    CellRendererVerlaengerung,
    CustomHeader,
    CellRendererOptionals,
  },
  data() {
    return {
      langConfig,
      reisefinderTitleHoverText:
        'Der Reisfinder dient als primäres Informationstool für das Sales Team. Es werden alle buchbaren Termine in der Zukunft angezeigt. ',
      dateAbflug: null,
      dateAnkunft: null,
      columnDefs: null,
      defaultColDef: {
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          suppressFilterButton: true,
        },
      },
      statusBar: {
        statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
      },
      filterActive: true,
      regionSelected: [],
      countrySelected: [],
      rundAufenthaltsreiseSelected: null,
      rundAufenthaltsreiseOptions: ['Rundreise', 'Aufenthaltsreise'],
      rowModelType: null,
      inputReiseterminkuerzel: null,
      inputReisetitel: null,
      pax: 1,
      zimmerkontingentBeruecksichtigen: false,
      garantiertFilterSelected: null,
      garantiertFilterOptions: ['Garantiert', 'Nicht garantiert'],
      reisekuerzelOptions: [],
      timeoutReiseterminkuerzelSearch: null,
      timeoutReisetitelSearch: null,
      allReisekuerzel: null,
      reisetitelOptions: [],
      reisetitelSearchLoading: false,
      reiseterminSearchLoading: false,
      autoSizeStrategy: null,
      ausgebuchteTermineCheckbox: false,
    };
  },
  created() {
    if (!this.countriesGroupedByRegions) {
      this.$store.dispatch(GET_ALL_COUNTRIES_GROUPED_BY_REGIONS);
    }

    this.rowModelType = 'serverSide';
    this.columnDefs = [
      {
        headerName: 'Reise',
        field: 'reise.reisekuerzel',
        filter: 'agTextColumnFilter',
        minWidth: 100,
        width: 100,
        tooltipField: 'reise.titel',
        pinned: 'left',
        sortable: true,
        headerComponent: 'CustomHeader',
      },
      {
        headerName: 'Reisetitel',
        field: 'reise.titel',
        filter: 'agTextColumnFilter',
        hide: true,
      },
      {
        headerName: 'Abflug DE',
        field: 'abreisedatum',
        valueFormatter: this.dateFormatterStandard,
        minWidth: 130,
        width: 130,
        pinned: 'left',
        sort: 'asc',
        sortable: true,
        headerComponent: 'CustomHeader',
      },
      {
        headerName: 'Ankunft DE',
        field: 'enddatum',
        valueFormatter: this.dateFormatterStandard,
        minWidth: 140,
        width: 140,
        pinned: 'left',
        sortable: true,
        headerComponent: 'CustomHeader',
      },
      { headerName: 'Dauer', field: 'metadata.reisedauer', minWidth: 80, width: 80 },
      {
        headerName: 'Gar.',
        headerTooltip: 'Garantiert',
        field: 'garantiert',
        cellRenderer: params => {
          const { value } = params;
          if (value) {
            return "<i class='fas fa-check text-success'/>";
          } else {
            return "<i class='ml-1 fas fa-times text-danger'/>";
          }
        },
        minWidth: 70,
        width: 70,
      },
      {
        headerName: 'TbR',
        headerTooltip: 'Tage bis Release',
        field: 'releasedatum',
        cellRenderer: params => {
          const daysUntilRelease = differenceInDays(parseISO(params.value), new Date());
          return daysUntilRelease;
        },
        minWidth: 80,
        width: 80,
      },
      {
        field: 'pax',
        headerName: 'PAX',
        cellRenderer: 'CellRendererPax',
        minWidth: 200,
        width: 200,
        filter: false,
        suppressRowTransform: true,
        cellStyle: {
          'line-height': 'normal',
          display: 'flex',
          //'align-items': 'center',
          'justify-items': 'center',
          overflow: 'visible',
        },
      },
      {
        headerName: 'Zimmerkontingente',
        field: 'zimmerkontingente',
        cellRenderer: 'CellRendererZimmerkontingente',
        width: 200,
        minWidth: 200,
        cellStyle: {
          'line-height': 'normal',
          display: 'flex',
          'align-items': 'center',
          'justify-items': 'center',
          overflow: 'visible',
        },
      },
      {
        headerName: 'DZ p.P.',
        field: 'basisPreis',
        cellRenderer: params => (params.value ? this.$options.filters.currency(params.value) : '-'),
        width: 120,
      },
      {
        headerName: 'EZZ p. P.',
        headerTooltip: 'Einzelzimmerzuschlag pro Person',
        field: 'einzelzimmerzuschlag',
        cellRenderer: params => this.getEinzelzimmerzuschlag(params.data),
        width: 120,
      },
      {
        headerName: 'TS Hin',
        field: 'metadata.transferszenarioHin',
        headerTooltip: 'Transferszenario Hin',
        minWidth: 70,
        width: 70,
        cellRenderer: params => params.value?.substring(0, 2),
      },
      {
        headerName: 'TS Rück',
        field: 'metadata.transferszenarioRueck',
        headerTooltip: 'Transferszenario Rück',
        minWidth: 70,
        width: 70,
        cellRenderer: params => params.value?.substring(0, 2),
      },
      {
        headerName: 'Flugverfügbarkeit',
        field: 'flugverfuegbarkeiten',
        cellRenderer: 'CellRendererFlugverfuegbarkeiten',
        width: 380,
      },
      {
        headerName: 'Verlängerungen',
        field: 'zusatzleistungen',
        cellRenderer: 'CellRendererVerlaengerung',
        width: 400,
      },
      {
        headerName: 'Optionals',
        field: 'zusatzleistungen',
        cellRenderer: 'CellRendererOptionals',
        width: 150,
      },
      {
        headerName: 'Aktionen',
        cellRenderer: 'CellRendererActionButtons',
        minWidth: 120,
        width: 120,
        pinned: 'right',
      },
      {
        headerName: 'Land',
        field: 'reise.country',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: params => {
            params.success(this.countriesFromStore());
          },
        },
        hide: true,
      },
      {
        field: 'reiseterminkuerzel',
        filter: 'agTextColumnFilter',
        hide: true,
      },
      {
        field: 'reisefinderDate',
        filter: 'agDateColumnFilter',
        hide: true,
      },
    ];
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
    this.getReisekuerzel();
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  computed: {
    ...mapGetters(['currentUserName', 'parsedRoles']),
    ...mapState({
      reisetermine: state => state.reisefinder.reisetermine,
      count: state => state.reisefinder.count,
      isBusy: state => state.reisefinder.isBusy,
      countriesGroupedByRegions: state => state.reisen.countriesGroupedByRegions,
    }),
    checkIfFilterSupplied() {
      return !(
        this.regionSelected.length === 0 &&
        this.countrySelected.length === 0 &&
        this.garantiertFilterSelected === null &&
        this.dateAnkunft === null &&
        this.dateAbflug === null &&
        this.rundAufenthaltsreiseSelected === null &&
        this.inputReiseterminkuerzel === null &&
        this.inputReisetitel === null &&
        this.zimmerkontingentBeruecksichtigen === false
      );
    },
  },
  methods: {
    onReload() {
      this.gridApi.onFilterChanged();
    },
    ausgebuchteTermineChanged() {
      this.gridApi.onFilterChanged();
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      this.regionSelected = [];
      this.countrySelected = [];
      this.dateAbflug = null;
      this.dateAnkunft = null;
      this.garantiertFilterSelected = null;
      this.zimmerkontingentBeruecksichtigen = false;
      this.rundAufenthaltsreiseSelected = null;
      this.inputReiseterminkuerzel = null;
      this.inputReisetitel = null;
      this.gridApi.onFilterChanged();
    },

    countriesFromStore() {
      if (this.countriesGroupedByRegions) {
        return Object.values(this.countriesGroupedByRegions)
          .flat()
          .map(item => item.country);
      } else return [];
    },
    filterAnwenden() {
      const countryFilterInstance = this.gridApi?.getFilterInstance('reise.country');
      const countries = [
        ...this.countrySelected.map(item => item.value),
        ...this.regionSelected
          .map(item => item.countries)
          .flat()
          .map(item => item.value),
      ];
      if (countries.length === 0) {
        countryFilterInstance.setModel(null);
      } else countryFilterInstance.setModel({ values: countries });

      // add rundreise filter
      const reisekuerzelFilterInstance = this.gridApi.getFilterInstance('reise.reisekuerzel');
      if (this.rundAufenthaltsreiseSelected) {
        reisekuerzelFilterInstance.setModel({
          filter: this.rundAufenthaltsreiseSelected,
        });
      } else {
        reisekuerzelFilterInstance.setModel(null);
      }

      // add reiseterminfilter
      const reiseterminkuerzelFilterInstance = this.gridApi.getFilterInstance('reiseterminkuerzel');
      if (this.inputReiseterminkuerzel) {
        const valuesReisekuerzel = this.inputReiseterminkuerzel.map(input => input.reisekuerzel);
        reiseterminkuerzelFilterInstance.setModel({
          filter: valuesReisekuerzel.join(','),
        });
      } else {
        reiseterminkuerzelFilterInstance.setModel(null);
      }

      const reisetitelFilterInstance = this.gridApi.getFilterInstance('reise.titel');
      if (this.inputReisetitel) {
        const valuesReisetitel = this.inputReisetitel.map(input => input.titel);
        reisetitelFilterInstance.setModel({
          filter: valuesReisetitel,
        });
      } else {
        reisetitelFilterInstance.setModel(null);
      }

      const dateRangeFilterInstance = this.gridApi.getFilterInstance('reisefinderDate');
      if (this.dateAbflug !== null && this.dateAnkunft !== null) {
        dateRangeFilterInstance.setModel({
          dateFrom: format(this.dateAbflug, 'yyyy-MM-dd'),
          dateTo: format(this.dateAnkunft, 'yyyy-MM-dd'),
          filterType: 'date',
          type: 'inRange',
        });
      } else if (this.dateAbflug !== null && this.dateAnkunft === null) {
        dateRangeFilterInstance.setModel({
          dateFrom: format(this.dateAbflug, 'yyyy-MM-dd'),
          dateTo: '2028-01-01',
          filterType: 'date',
          type: 'inRange',
        });
      } else if (this.dateAbflug === null && this.dateAnkunft !== null) {
        dateRangeFilterInstance.setModel({
          dateFrom: format(new Date(), 'yyyy-MM-dd'),
          dateTo: format(this.dateAnkunft, 'yyyy-MM-dd'),
          filterType: 'date',
          type: 'inRange',
        });
      } else {
        dateRangeFilterInstance.setModel(null);
      }
      const filtersApplied = Object.keys(this.gridApi.getFilterModel());
      applicationInsights.trackEventWithRouteAndUser(
        { name: `Applied Filter Reisefinder` },
        {
          filters: filtersApplied,
          roles: this.parsedRoles,
          currentUserName: this.currentUserName,
        }
      );
      this.$bvModal.hide('modal-reisefinder');
      setTimeout(() => this.gridApi.onFilterChanged(), 100);
    },
    setRegionFilter(region) {
      this.regionSelected = region;
      if (document) {
        setTimeout(() => document.getElementById('filter-kontinent').focus(), 50);
      }
      // set this region filter in filterModel of ag grid
      // this.gridApi.onFilterChanged()
    },
    setCountryFilter(country) {
      this.countrySelected = country;
      if (document) {
        setTimeout(() => document.getElementById('filter-land').focus(), 50);
      }
      // set this region filter in filterModel of ag grid
      // this.gridApi.onFilterChanged()
    },
    handleKeyDown(event) {
      // Check if the event's key is "k" and the appropriate modifier key is pressed
      if (event.key === 'k' && (event.metaKey || (event.ctrlKey && !event.metaKey))) {
        // Handle the Cmd + K (or Ctrl + K on Windows) event here
        this.filterActive = !this.filterActive;
        if (this.filterActive) {
          this.$bvModal.show('modal-reisefinder');
          this.focusReisekuerzelFilter();
        } else {
          this.$bvModal.hide('modal-reisefinder');
        }
      }
      if (event.key === 'Escape' && this.checkIfFilterSupplied) {
        this.resetAllFilters();
      }
      if (
        event.key === 'Enter' &&
        (event.metaKey || (event.ctrlKey && !event.metaKey)) &&
        this.filterActive
      ) {
        this.$bvModal.hide('modal-reisefinder');
        this.filterAnwenden();
      }
    },
    onClickFilterButton() {
      this.filterActive = !this.filterActive;
      if (this.filterActive) {
        this.$bvModal.show('modal-reisefinder');
        this.focusReisekuerzelFilter();
      }
    },
    dateFormatterStandard(params) {
      const abfragedatum = params.value;
      if (!abfragedatum) return '';
      const parsedDate = parseISO(abfragedatum);
      const formattedDate = format(parsedDate, 'dd.MM.yyyy');
      return formattedDate;
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    createDatasource(server) {
      return {
        getRows: async params => {
          const response = await server.getData(params.request);
          if (response.success) {
            params.success({
              rowData: response.rows,
            });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
          } else {
            params.fail();
          }
        },
      };
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const updateData = () => {
        const server = this.server();
        const datasource = this.createDatasource(server);
        this.gridApi.setServerSideDatasource(datasource);
      };
      updateData();
    },
    server() {
      return {
        getData: async request => {
          const enhancedRequest = {
            ...request,
            interessenten: this.pax,
            zimmerkontingentBeruecksichtigen: this.zimmerkontingentBeruecksichtigen,
            garantiertFilterSelected: this.garantiertFilterSelected,
            ausgebuchteTermineAnzeigen: this.ausgebuchteTermineCheckbox,
          };
          console.log('[Datasource] - rows requested by grid: ', enhancedRequest);
          const response = await this.$store.dispatch(GET_REISETERMINE, enhancedRequest);
          return {
            success: true,
            rows: response,
          };
        },
      };
    },
    async getReisekuerzel() {
      let query = buildQuery({
        select: ['reisekuerzel', 'id'],
        filter: { hasFutureReisetermine: true },
        count: true,
      });
      this.reiseterminSearchLoading = true;
      await apiService
        .get('Reise' + query)
        .then(res => {
          this.allReisekuerzel = res?.data?.result?.value;
          this.reisekuerzelOptions = this.allReisekuerzel;
        })
        .catch(err => {
          console.err(err);
        });
      this.reiseterminSearchLoading = false;
      this.reisekuerzelOptions = this.allReisekuerzel;
      document.getElementById('filter-reisekuerzel').focus();
    },
    onSearchChangeReisetermin($event) {
      if ($event.length > 6) {
        this.reiseterminSearchLoading = true;

        if (this.timeoutReiseterminkuerzelSearch) {
          clearTimeout(this.timeoutReiseterminkuerzelSearch);
        }
        this.timeoutReiseterminkuerzelSearch = setTimeout(() => {
          apiService
            .get(
              `Reisetermin?$filter=startswith(reiseterminkuerzel,'${$event}') and abreisedatum ge ${format(
                new Date(),
                'yyyy-MM-dd'
              )}&$select=reiseterminkuerzel,id`
            )
            .then(res => {
              this.reiseterminSearchLoading = false;
              const reisetermine = [...res.data.result.value] || [];
              this.reisekuerzelOptions = reisetermine.map(reisetermin => {
                return {
                  reisekuerzel: reisetermin.reiseterminkuerzel,
                  id: reisetermin.id,
                };
              });
            })
            .catch(err => {
              console.error(err);
              this.reiseterminSearchLoading = false;
            });
        }, 500); // delay
      } else {
        if (this.reisekuerzelOptions.length !== this.allReisekuerzel.length)
          this.reisekuerzelOptions = this.allReisekuerzel;
      }
    },
    onSearchChangeReisetitel($event) {
      if ($event.length > 0) {
        this.reisetitelSearchLoading = true;
        if (this.timeoutReisetitelSearch) {
          clearTimeout(this.timeoutReisetitelSearch);
        }
        this.timeoutReisetitelSearch = setTimeout(() => {
          apiService
            .get(
              `Reise?$filter=contains(titel,'${$event}')&$expand=reisetermine($filter=abreisedatum ge now();$count=true;$top=0)&$select=reisekuerzel,titel,id`
            )
            .then(res => {
              this.reisetitelSearchLoading = false;
              const reisenWithFutureReiseterminen = res.data.result.value.filter(
                reise => reise['reisetermine@odata.count'] > 0
              );
              const reisen = reisenWithFutureReiseterminen.length > 0 ? reisenWithFutureReiseterminen : [];
              this.reisetitelOptions = reisen.map(reise => {
                return {
                  titel: reise.titel,
                  id: reise.id,
                };
              });
            })
            .catch(err => {
              console.error(err);
              this.reisetitelSearchLoading = false;
            });
        }, 500); // delay
      }
    },
    changeReisekuerzel($event) {
      this.inputReiseterminkuerzel = $event;
      this.focusReisekuerzelFilter();
    },
    changeReisetitel($event) {
      this.inputReisetitel = $event;
      this.focusReisetitelFilter();
    },
    getRowHeight(params) {
      return params.data
        ? Math.max(
            Math.ceil(params.data.zimmerkontingente?.filter(z => z.isDeleted === false).length / 4) * 45,
            45
          )
        : 45;
    },
    focusReisekuerzelFilter() {
      if (document) {
        setTimeout(() => document.getElementById('filter-reisekuerzel').focus(), 50);
      }
    },
    focusReisetitelFilter() {
      if (document) {
        setTimeout(() => document.getElementById('filter-reisetitel').focus(), 50);
      }
    },
    getEinzelzimmerzuschlag(data) {
      const ezKontingent = data.zimmerkontingente.find(
        zimmerkontingent => zimmerkontingent.zimmertyp === 'EZ'
      );
      if (ezKontingent) {
        return this.$options.filters.currency(ezKontingent.zuschlag);
      } else return '-';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 600;
  font-size: 14px;
}
.filterReisefinder {
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 4px;
}
.filterReisefinder:hover {
  background-color: rgb(245, 245, 249);
  color: rgb(54, 153, 255) !important;
}
.filterIcon {
  color: inherit;
}
.filterActive {
  box-shadow: inset 1px 1px 2px #717a8e42;
  transform: translateY(1px);
  background-color: rgb(245, 245, 249);
  color: rgb(54, 153, 255) !important;
}
.inputReiseterminkuerzel::placeholder {
  color: #adadad;
}
.ag-icon-filter {
  display: none;
}
:deep(.mx-input-wrapper > input) {
  font-size: 1rem;
  border: 1px solid #e8e8e8;
}
</style>
